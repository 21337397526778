import React, { useState } from "react";
//import * as Yup from "yup";
//import axios from "axios";
import "./style.css";
import logo from "../../assets/Logo.svg";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import RegisterForm from "../../components/RegisterPageComponents/RegisterFrom";

export default function LoginPage() {
  const [data, setData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedAge, setSelectedAge] = useState(28);
  const [gender, setGender] = useState("Uomo");
  const [token, setToken] = useState("");
  const [isNewCustomer, setIsNewCustomer] = useState(true)

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  let userEmail;
  const ageOptions = [];
  for (let i = 5; i <= 99; i++) {
    ageOptions.push(i.toString());
  }
  if (token) {
    const decodedData = jwt_decode(token);
    
  }
  return (
    <div className="login-container">
      <div className="left-login-block">
        <div className="logo footer-logo">
          <img src={logo} alt="logo" />
          <div className="logo-txt">
            <p>MILANO DELIVERY </p>
            <p>QUICK FASHION DELIVERY </p>
          </div>
        </div>
        <div className="login-title-box">
          <h3 className="login-title">Welcome!</h3>
          <p className="login-descr">
            Unisciti alla nostra community, registerati per esplorare ed
            abbracciare il mondo della moda su misura per te.
          </p>
        </div>
      </div>

      {/* Login Form */}
      <RegisterForm
      
      />
    </div>
  );
}