import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import firebase from '../firebase';
import {v4 as uuidv4} from "uuid";
import { wait } from '@testing-library/user-event/dist/utils';
import { set } from 'firebase/database';

const RegisterForm = ({ openPopup }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [birthday, setBirthday] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [cap, setCap] = useState('');
  const [isRegisterButtonEnabled, setIsRegisterButtonEnabled] = useState(false);
  const [isNameCorrect, setIsNameCorrect] = useState(true);
  const [isSurnameCorrect, setIsSurnameCorrect] = useState(true);
  const [isEmailCorrect, setIsEmailCorrect] = useState(true);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);
  const [isBirthdayCorrect, setIsBirthdayCorrect] = useState(true);
  const [isGenderCorrect, setIsGenderCorrect] = useState(true);
  const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
  const [isAddressCorrect, setIsAddressCorrect] = useState(true);
  const [isCityCorrect, setIsCityCorrect] = useState(true);
  const [isCapCorrect, setIsCapCorrect] = useState(true);


  const navigate = useNavigate();

  async function checkUserMail(mail) {
    const user = await firebase.firestore()
    .collection("users")
    .where('email', '==', mail)
    .get();
    if (user.empty || user.docs.length == 0) {
      return true;
    }else{
      console.log("User already exists: " + user.docs[0].data());
      return false;
    }
}

  const registerUser = () => {
    if (!checkUserMail(email)) {
      //alert("L'email è già registrata");
      //return;
    }
    let id = uuidv4();
    const user = {
      id: id,
      name: name,
      surname: surname,
      email: email,
      password: password,
      birthday: birthday,
      gender: gender,
      phone: phone,
      address: address,
      city: city,
      cap: cap
    }

    firebase.firestore()
    .collection("users")
    .doc(id).set(user)
    .then(() => {
      alert("Utente registrato con successo!");
      navigate("/login");
    }
    )
    .catch(alert);
  }

  const checkInputName = (name) => {
    if (name.length < 3) {
      setIsNameCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsNameCorrect(true);
    }
    checkEnableRegisterButton();
  }
  
  const checkInputSurname = (surname) => {
    if (surname.length < 3) {
      setIsSurnameCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsSurnameCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputEmail = (email) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (email.match(isValidEmail)) {
      setIsEmailCorrect(true);
    }else{
      setIsEmailCorrect(false);
      setIsRegisterButtonEnabled(false);
    }
    checkEnableRegisterButton();
  }

  const checkInputPassword = (password) => {
    if (password.length < 6) {
      setIsPasswordCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsPasswordCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputBirthday = (birthday) => {
    alert(birthday);
    if (birthday == "") {
      setIsBirthdayCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsBirthdayCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputGender = (gender) => {
    if (gender == "") {
      setIsGenderCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsGenderCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputPhone = (phone) => {
    if (phone.length < 10) {
      setIsPhoneCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsPhoneCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputAddress = (address) => {
    if (address.length < 4) {
      setIsAddressCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsAddressCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputCity = (city) => {
    if (city.length < 4) {
      setIsCityCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsCityCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkInputCap = (cap) => {
    if (cap.length < 4) {
      setIsCapCorrect(false);
      setIsRegisterButtonEnabled(false);
    }else{
      setIsCapCorrect(true);
    }
    checkEnableRegisterButton();
  }

  const checkEnableRegisterButton = () => {
    if (isNameCorrect && isSurnameCorrect && isEmailCorrect && isPasswordCorrect && isBirthdayCorrect && isGenderCorrect && isPhoneCorrect && isAddressCorrect && isCityCorrect && isCapCorrect
        && name != "" && surname != "" && email != "" && password != "" && birthday != "" && gender != "" && phone != "" && address != "" && city != "" && cap != ""
    ) {
      setIsRegisterButtonEnabled(true);
    }else{
      setIsRegisterButtonEnabled(false);
    }
  }

  return (
    <div className="register-form-wrapper">
        <h2 className="form-title">Registrati</h2>
        <p className="form-descr">Inserisci i dati per registrarti</p>
        <form>
          <div style={{display:"flex"}}>
            <div className="form-item">
              <label className="input-label" htmlFor="name">
                Nome
              </label>
              <input
                className={"input-field" + (isNameCorrect ? "" : " mandatory-field")}
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                value={name} 
                onChange={(e) => { setName(e.target.value); checkInputName(e.target.value) }}
              />
              {!isNameCorrect ? (
                <div className="error-message">Il nome deve contenere almeno 4 caratteri</div>
              ) : null}
            </div>
            <div className="form-item">
              <label className="input-label" htmlFor="surname">
                Cognome
              </label>
              <input
                className={"input-field" + (isSurnameCorrect ? "" : " mandatory-field")}
                type="text"
                id="surname"
                name="surname"
                placeholder="Cognome"
                value={surname} onChange={(e) => { setSurname(e.target.value); checkInputSurname(e.target.value) }}
              />
              {!isSurnameCorrect ? (
                <div className="error-message">Il cognome deve contenere almeno 4 caratteri</div>
              ) : null}
            </div>
          </div>
          <div style={{display:"flex"}}>
            <div className="form-item">
              <label className="input-label" htmlFor="email">
                Email
              </label>
              <input
                className={"input-field" + (isEmailCorrect ? "" : " mandatory-field")}
                type="text"
                id="email"
                name="email"
                placeholder="@gmail.com"
                autoComplete="email"
                value={email} onChange={(e) => { setEmail(e.target.value); checkInputEmail(e.target.value) }}
              />
              {!isEmailCorrect ? (
                <div className="error-message">Inserisci una email valida</div>
              ) : null}
            </div>
            <div className="form-item">
              <label className="input-label" htmlFor="password">
                Password
              </label>
              <input
                className={"input-field" + (isPasswordCorrect ? "" : " mandatory-field")}
                type="password"
                id="password"
                name="password"
                placeholder="*******"
                value={password} onChange={(e) => { setPassword(e.target.value); checkInputPassword(e.target.value) }}
              />
              {!isPasswordCorrect ? (
                <div className="error-message">La password deve contenere almeno 6 caratteri</div>
              ) : null}
            </div>
          </div>
          <div style={{display:"flex"}}>
            <div className="form-item">
              <label className="input-label" htmlFor="birthday">
                Data di nascita
              </label>
              <input
                className={"input-field" + (isBirthdayCorrect ? "" : " mandatory-field")}
                type="date"
                id="birthday"
                name="birthday"
                placeholder="Data di nascita"
                value={birthday} 
                onChange={(e) => { setBirthday(e.target.value);}}
              />
              {!isBirthdayCorrect ? (
                <div className="error-message">Inserisci la data di nascita</div>
              ) : null}
            </div>
            <div className="form-item">
              <label className="input-label" htmlFor="gender">
                Gender
              </label>
              <select className={"input-field" + (isGenderCorrect ? "" : " mandatory-field")} id="gender" name="gender" value={gender} onChange={(e) => { setGender(e.target.value); checkInputGender(e.target.value) }}>
                <option value="">Select Gender</option>
                <option value="male">Uomo</option>
                <option value="female">Donna</option>
                <option value="other">Altro</option>
              </select>
              {!isGenderCorrect ? (
                <div className="error-message">Inserisci il gender</div>
              ) : null}
            </div>
          </div>
          <div style={{display:"flex"}}>
            <div className="form-item">
              <label className="input-label" htmlFor="phone">
                Telefono
              </label>
              <input
                className={"input-field" + (isPhoneCorrect ? "" : " mandatory-field")}
                type="text"
                id="phone"
                name="phone"
                placeholder="Telefono"
                value={phone} onChange={(e) => { setPhone(e.target.value); checkInputPhone(e.target.value) }}
              />
              {!isPhoneCorrect ? (
                <div className="error-message">Il telefono deve contenere almeno 10 caratteri</div>
              ) : null}
            </div>
            <div className="form-item">
              <label className="input-label" htmlFor="address">
                Indirizzo
              </label>
              <input
                className={"input-field" + (isAddressCorrect ? "" : " mandatory-field")}
                type="text"
                id="address"
                name="address"
                placeholder="Indirizzo"
                value={address} onChange={(e) => { setAddress(e.target.value); checkInputAddress(e.target.value) }}
              />
              {!isAddressCorrect ? (
                <div className="error-message">L'indirizzo deve contenere almeno 4 caratteri</div>
              ) : null}
            </div>
          </div>
          <div style={{display:"flex"}}>
            <div className="form-item">
              <label className="input-label" htmlFor="city">
                Città
              </label>
              <input
                className={"input-field" + (isCityCorrect ? "" : " mandatory-field")}
                type="text"
                id="city"
                name="city"
                placeholder="Città"
                value={city} onChange={(e) => { setCity(e.target.value); checkInputCity(e.target.value) }}
              />
              {!isCityCorrect ? (
                <div className="error-message">La città deve contenere almeno 4 caratteri</div>
              ) : null}
            </div>
            <div className="form-item">
              <label className="input-label" htmlFor="cap">
                CAP
              </label>
              <input
                className={"input-field" + (isCapCorrect ? "" : " mandatory-field")}
                type="text"
                id="cap"
                name="cap"
                placeholder="CAP"
                value={cap} onChange={(e) => { setCap(e.target.value); checkInputCap(e.target.value) }}
              />
              {!isCapCorrect ? (
                <div className="error-message">Il CAP deve contenere almeno 4 caratteri</div>
              ) : null}
            </div>
          </div>
          <div>
            <button
              className={"register-form-btn" + (isRegisterButtonEnabled ? "" : " disabled")}
              type="button"
              onClick={(e)=> {e.preventDefault(); registerUser()}}
              {...isRegisterButtonEnabled ? {} : {disabled: "disabled"} }
            >
              Register &rarr;
            </button>
          </div>
        </form>
      </div>
  )
}

export default RegisterForm;
