import React, { useEffect, useState } from 'react';
import './style.css';
import logo from '../../assets/Logo.svg';
import userIcon from '../../assets/user 1.svg'
import firebase from '../firebase';

export default function Header({ title, subtitle, headerStyleContainer,  userId=null }) {
    const [loading, setLoading] = useState(true);
    const [userEmail, setUserEmail] = useState("");
    
    async function getUserEmail(userId) {
        const user = await firebase.firestore()
        .collection("users")
        .where('id', '==', userId)
        .get();
        if (user.empty || user.docs.length == 0 || user.docs.length > 1) {
          return "error";
        }  
        return user.docs[0].data().email;
    }

    useEffect(() => {
        if (userId) {
            getUserEmail(userId).then((email) => {
                setUserEmail(email);
                setLoading(false);
            });
        }
    }, [userId]);

    return (
        <>
            <div className={headerStyleContainer}>
                <div>
                    <div className="header-navigation">
                        <div className="logo">
                            <img src={logo} alt="logo" />
                            <div className="logo-txt">
                                <p>MILANO DELIVERY </p>
                                <p>QUICK FASHION DELIVERY </p>
                            </div>
                        </div>
                        {userId ? (
                            loading ? "Loading..." : <div className="customer-email"><img className='user-icon' src={userIcon} alt="user" /><div>{userEmail}</div></div>
                        ) : (
                            <button
                                className="login-btn"
                                onClick={() => (window.location.href = '/login')}
                            >
                                Log in
                            </button>
                        )}
                    </div>
                    <div className="header-txt">
                        <h1 className="header-title">{title}</h1>
                        <h2 className="header-subtitle">{subtitle}</h2>
                    </div>
                </div>
            </div>
        </>
    );
}