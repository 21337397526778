import React, {useEffect, useState} from 'react'
import firebase from '../firebase';
import googleIcon from "../../assets/googleIcon.svg";
import { useNavigate } from "react-router-dom";
const LoginForm = ({ formik, openPopup, handleSignInWithGoogle }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  async function Login() {
    const users = await firebase.firestore()
    .collection("users")
    .where('email', '==', email)
    .where('password', '==', password)
    .get();
    if (users.empty || users.docs.length == 0 || users.docs.length > 1) {
      console.log('No matching users.');
      alert("Error! User not found. Please check your credentials and try again.")
      return;
    }  
    window.sessionStorage.setItem("userId", users.docs[0].data().id);
    navigate("/customer");
  }

  return (
    <div className="login-form-wrapper">
        <h2 className="form-title">Log In</h2>
        <p className="form-descr">Inserisci le tue credenziali per accedere</p>
        <form>
          <div className="form-item">
            <label className="input-label" htmlFor="email">
              Email
            </label>
            <input
              className="input-field"
              type="text"
              id="email"
              name="email"
              placeholder="@gmail.com"
              autoComplete="email"
              value={email} 
              onChange={(e) => { setEmail(e.target.value) }}
            />
            {/*formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null*/}
          </div>
          <div className="form-item">
            <label className="input-label" htmlFor="password">
              Password
            </label>
            <input
              className="input-field"
              type="password"
              id="password"
              name="password"
              placeholder="*******"
              autoComplete="current-password"
              value={password} 
              onChange={(e) => { setPassword(e.target.value) }}
            />
            {/*formik.touched.password && formik.errors.password ? (
              <div className="error-message">{formik.errors.password}</div>
            ) : null*/}
          </div>
          <div>
            <button
              onClick={(e)=> {e.preventDefault();Login();}}
              className="login-form-btn"
              type="button"
            >
              Log In &rarr;
            </button>
          </div>
          <div>
            <div className="login-line-box">
              <hr className="horizontal-line" />
              <div className="horizontal-line-txt">or</div>
              <hr className="horizontal-line" />
            </div>
            <div>
              {/* redirect to userData from google auth */}
              <button
                onClick={handleSignInWithGoogle}
                className="login-form-btn red-bg"
                type="submit"
              >
                <img
                  className="google-icon"
                  src={googleIcon}
                  alt="google-icon"
                />
                Sign in with Google
              </button>
            </div>
            <hr className="horizontal-line" />
            <div className="horizontal-line-txt"><p className="form-descr">Non sei registrato? <a href="/register">Registrati qui!</a></p></div>
          </div>
        </form>
      </div>
  )
}

export default LoginForm;
